/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */

const {component: noCssComponent} = require('./cartIconAppConfigNoCss');

module.exports = {
  default: {
    component: noCssComponent,
  },
};
