import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {CartIconApp} from './components/CartIconApp/CartIconApp';
import {CART_ICON_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';
import {withStrictMode} from '@wix/wixstores-client-common-components/dist/es/src/HOC/strictMode';
import {withLeanStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withLeanStyles';

export const component = withSentryErrorBoundary(withLeanStyles(withStrictMode(CartIconApp), {strictMode: true}), {
  dsn: CART_ICON_DSN,
  config: {environment: 'Native Component'},
});
